import React, { useEffect, useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import moment from 'moment-timezone'
import * as ExcelJS from 'exceljs'
import Button from '../components/atoms/button'
import Page from '../components/atoms/page'
import Table from '../components/ADS/molecules/table'
import Pagination from '../components/ADS/atoms/pagination'
import { SiGooglesheets } from 'react-icons/si'
import { FcPrint as InvoiceIcon } from 'react-icons/fc'
import Format from '../utils/format'
import shortUUID from 'short-uuid'
import { saveAs } from 'file-saver'
import AuthStore from '../stores/AuthStore'

const PAGE_ORDER_NUM = 10

const headers = Format.taxInvoiceExcelHeaders

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

function convertSettlementExceptTitle(type) {
  switch (type) {
    case 'alfarmPhotoReview':
      return '정산제외 - 올팜 사진후기'

    case 'alfarm_quiz':
      return '정산제외 - 올팜 퀴즈'

    case 'alfarm_store_ad':
      return '정산제외 - 올팜 상점'

    case 'alfarm_trendQuiz':
      return '정산제외 - 우주최저가'

    case 'alfarm_egg':
      return '정산제외 - 올팜 알깨기/포춘쿠키'

    case 'fastArrivalCjLogistics':
      return '정산제외 - 바로도착(CJ)'

    case 'fastArrivalDelivus':
      return '정산제외 - 바로도착(딜리버스)'

    default:
      return '정산제외 - 기타'
  }
}

const TaxInvoiceScreen = observer(() => {
  const [taxInvoices, setTaxInvoices] = useState([])
  // const [orders, setOrders] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const translator = shortUUID()

  // hardcoding the date range for now
  const startDate = '2024-01-01'

  useEffect(() => {
    const endDate = '2024-09-30'
    // if (AuthStore.isMasterAccount) {
    //   endDate = '2024-09-30'
    // }
    const fetchTaxInvoices = async () => {
      const result = await backendApis.loadSellerTaxInvoice(startDate, endDate)
      if (result?.status === 200) {
        setTaxInvoices(result?.data || [])
      }
    }
    fetchTaxInvoices()
  }, [AuthStore.isMasterAccount])

  const taxInvoiceColumns = useMemo(
    () => [
      { Header: '해당년월', accessor: 'date' },
      { Header: '수수료대상 거래금액', accessor: 'settlementAndCommission' },
      { Header: '서비스 이용 수수료', accessor: 'totalRevenue' },
      {
        Header: '매출에누리 (올웨이즈 부담 할인금)',
        accessor: 'totalRevenueDiscount',
      },
      { Header: '합계액', accessor: 'totalDiscountedRevenue' },
      { Header: '공급가액', accessor: 'supplyPrice' },
      { Header: '부가세', accessor: 'VAT' },
      { Header: '엑셀 다운로드', accessor: 'downloadTaxInvoiceButton' },
    ],
    [],
  )

  const taxInvoiceData = useMemo(() => {
    return taxInvoices
      ?.slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.sort(
        (a, b) =>
          new Date(b?.taxInvoiceTargetDate) - new Date(a?.taxInvoiceTargetDate),
        0,
      )
      ?.map((taxInvoice, index) => {
        const date = moment(taxInvoice?.taxInvoiceTargetDate).format('YYYY-MM')

        const totalRevenueDiscount =
          taxInvoice?.totalDiscountAmountForRevenueDiscount || 0

        const settlementExceptAmountRevenue =
          (taxInvoice?.settlementExceptAmount || 0) -
          (taxInvoice?.nonRevenueSettlementExceptAmount || 0)

        const totalRevenue =
          (taxInvoice?.totalCommissionAmount || 0) +
          (taxInvoice?.totalPlatformSupportPriceRevenue || 0) +
          settlementExceptAmountRevenue

        const totalDiscountedRevenue = totalRevenue - totalRevenueDiscount
        const VAT = Math.floor(totalDiscountedRevenue / 11)
        const supplyPrice = totalDiscountedRevenue - VAT

        return {
          date: <div className='text-left'>{date}</div>,
          settlementAndCommission: (
            <div className='text-right'>
              {(
                (taxInvoice?.totalSettlementAmount || 0) +
                totalRevenue +
                (taxInvoice?.nonRevenueSettlementExceptAmount || 0)
              )?.toLocaleString()}
            </div>
          ),
          totalRevenue: (
            <div className='text-right'>{totalRevenue?.toLocaleString()}</div>
          ),
          totalRevenueDiscount: (
            <div className='text-right'>
              {totalRevenueDiscount?.toLocaleString()}
            </div>
          ),
          totalDiscountedRevenue: (
            <div className='text-right'>
              {totalDiscountedRevenue?.toLocaleString()}
            </div>
          ),
          supplyPrice: (
            <div className='text-right'>{supplyPrice?.toLocaleString()}</div>
          ),
          VAT: <div className='text-right'>{VAT?.toLocaleString()}</div>,
          downloadTaxInvoiceButton: (
            <Button
              size='sm'
              className='flex items-center whitespace-nowrap'
              appearance='gray'
              type='button'
              onClick={async () => {
                // 해당 월, 셀러에 해당하는 tax-invoice document 가져오기
                SellerStore.setIsLoading(true)

                const startDate = moment(taxInvoice?.taxInvoiceTargetDate)
                  .startOf('month')
                  .format('YYYY-MM-DD')
                const endDate = moment(taxInvoice?.taxInvoiceTargetDate)
                  .endOf('month')
                  .format('YYYY-MM-DD')
                const targetTaxInvoice = await backendApis.loadSellerTaxInvoice(
                  startDate,
                  endDate,
                )

                // 해당 tax-invoice document의 taxInvoiceRawIds 배열 추출
                const targetTaxInvoiceRawIds =
                  targetTaxInvoice.data[0].taxInvoiceRawIds

                // taxInvoiceRawIds 배열을 이용하여 해당 월의 주문 목록 가져오기
                let monthlyOrders = []

                for (const taxInvoiceRawId of targetTaxInvoiceRawIds) {
                  let weeklyOrders = []
                  let isLoadingOrders = true

                  while (isLoadingOrders) {
                    const result = await backendApis.loadSellerTaxInvoiceOrders(
                      taxInvoiceRawId,
                      weeklyOrders.length,
                    )
                    if (result.data.length > 0) {
                      weeklyOrders = [...weeklyOrders, ...result.data]
                    } else {
                      isLoadingOrders = false
                      monthlyOrders = [...monthlyOrders, ...weeklyOrders]
                      break
                    }
                  }
                }

                // taxInvoiceRawIds 배열을 이용하여 해당 월의 정산제외 목록 가져오기
                let monthlySettlementExcepts = []

                for (const taxInvoiceRawId of targetTaxInvoiceRawIds) {
                  let weeklySettlementExcepts = []
                  let isLoadingOrders = true

                  while (isLoadingOrders) {
                    const result =
                      await backendApis.loadSellerTaxInvoiceSettlementExcepts(
                        taxInvoiceRawId,
                        weeklySettlementExcepts.length,
                      )
                    if (result.data.length > 0) {
                      weeklySettlementExcepts = [
                        ...weeklySettlementExcepts,
                        ...result.data,
                      ]
                    } else {
                      isLoadingOrders = false
                      monthlySettlementExcepts = [
                        ...monthlySettlementExcepts,
                        ...weeklySettlementExcepts,
                      ]
                      break
                    }
                  }
                }

                const workbook = new ExcelJS.Workbook()
                const worksheet = workbook.addWorksheet('세금계산서 상세내역')

                const worksheetData = monthlyOrders.map((order) => {
                  order.platformSupportPriceRevenue = Object.keys(
                    order,
                  ).includes('platformSupportPriceRevenue')
                    ? order?.platformSupportPriceRevenue || 0
                    : Math.max(-(order?.platformSupportPrice || 0), 0)
                  return {
                    // TODO: 받아온 order 정보를 엑셀 worksheetData로 가공
                    ...order,
                    totalPrice:
                      (order?.settlementAmount || 0) +
                      (order?.commissionAmount || 0) +
                      order.platformSupportPriceRevenue,
                    addressId:
                      order?._id === '-'
                        ? ''
                        : `${Format.DateToday(order?.payedAt)}${
                            checkIfValidUUID(order?.addressInfo?.addressId) &&
                            translator.fromUUID(
                              order?.addressInfo?.addressId || order?._id,
                            )
                          }`.substring(2),
                    paymentType:
                      order?.paymentInfo?.metaData?.response?.pay_method ===
                      'point'
                        ? '현금'
                        : '카드',
                    platformSupportPriceRevenue:
                      order?.platformSupportPriceRevenue || 0,
                    discountAmountForRevenueDiscount:
                      order?.discountAmountForRevenueDiscount || 0,
                    taxInvoicePrice:
                      (order?.commissionAmount || 0) +
                      (order?.platformSupportPriceRevenue || 0) -
                      (order?.discountAmountForRevenueDiscount || 0),
                    timeStamp: moment(order?.orderConfirmedTimeStamp).format(
                      'YYYY-MM-DD HH:mm',
                    ),

                    // 기타 필요한 필드들 추가
                  }
                })

                worksheetData.push(
                  ...monthlySettlementExcepts
                    ?.filter((elem) => {
                      if (
                        ['nonRevenueException'].includes(elem?.type) ||
                        (['newItemReviewEvent']?.includes(elem?.type) &&
                          moment(elem?.taxInvoiceRawCreatedAt) >=
                            moment('2024-03-07'))
                      ) {
                        return false
                      }
                      return true
                    })
                    ?.map((each) => {
                      return {
                        _id: convertSettlementExceptTitle(each?.type),
                        commissionAmount: each?.settlementExceptValue || 0,
                        taxInvoicePrice: each?.settlementExceptValue || 0,
                        timeStamp: moment(each?.createdAt).format(
                          'YYYY-MM-DD HH:mm',
                        ),
                      }
                    }),
                )

                if (taxInvoice?.totalRevenueDiscountBeforeOrderSeparation) {
                  worksheetData.push({
                    _id: '매출에누리 (올웨이즈 부담)',
                    discountAmountForRevenueDiscount:
                      taxInvoice.totalRevenueDiscountBeforeOrderSeparation,
                    taxInvoicePrice:
                      -taxInvoice.totalRevenueDiscountBeforeOrderSeparation,
                  })
                }
                worksheet.columns = headers
                worksheet.addRows(worksheetData)
                worksheet.getColumn(3).alignment = { wrapText: true }
                const headerRow = worksheet.getRow(1)
                headers.forEach((header, index) => {
                  headerRow.getCell(index + 1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFEA3F49' },
                  }
                })
                worksheet.getRow(1).font = {
                  color: { argb: 'FFFFFFFF' },
                  bold: true,
                }
                const mimeType = {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                }
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], mimeType)
                const saveDate = new Date(taxInvoice?.taxInvoiceTargetDate)
                const year = saveDate.getFullYear()
                const month = saveDate.getMonth() + 1

                saveAs(
                  blob,
                  `${
                    SellerStore.sellerInfo.sellerDisplayName
                  }_세금계산서 발행내역_${year}-${
                    month < 10 ? `0${month}` : month
                  }.xlsx`,
                )

                SellerStore.setIsLoading(false)
              }}
            >
              <SiGooglesheets className='mr-2' />
              세금계산서 다운로드
            </Button>
          ),
          // index: (
          //   <div className='text-right'>
          //     {index + 1 + PAGE_ORDER_NUM * pageIndex}
          //   </div>
          // ),
          // option: tableUtils.parseOptionText(
          //   order?.itemInfo?.optionsInfo,
          //   order?.selectedOption,
          // ),
          // addressId,
          // totalPrice: (
          //   <div className='text-right'>
          //     {(order?.totalPrice || 0).toLocaleString()}
          //   </div>
          // ),
          // // option: optionsText.map((option) => <div key={option}>{option}</div>),
          // platformSupportPrice: (
          //   <div className='text-right'>
          //     {platformSupportPrice.toLocaleString()}
          //   </div>
          // ),
          // platformSupportPriceRevenue: (
          //   <div className='text-right'>
          //     {platformSupportPriceRevenue.toLocaleString()}
          //   </div>
          // ),
          // shippingFee: (
          //   <div className='text-right'>
          //     {order?.shippingFee.toLocaleString()}
          //   </div>
          // ),
          // // address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          // // detailAddress: order.addressInfo?.detailAddress,
          // // recipient: Format.Recipient(order.addressInfo?.recipient),
          // // recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          // originallySettledAmount: (
          //   <div className='text-right'>
          //     {originallySettledAmount.toLocaleString()}
          //   </div>
          // ),
          // originalCommissionAmount: (
          //   <div className='text-right'>
          //     {originalCommissionAmount.toLocaleString()}
          //   </div>
          // ),
          // commissionRate: (
          //   <div className='text-right'>
          //     {Math.round(getCommissionRate(order) * 100 * 100) / 100}%
          //   </div>
          // ),
          // settlementAmount: (
          //   <div className='text-right'>
          //     {settlementAmount.toLocaleString()}
          //   </div>
          // ),
          // discountAmountForRevenueDiscount: (
          //   <div className='text-right'>
          //     {discountAmountForRevenueDiscount.toLocaleString()}
          //   </div>
          // ),
          // couponNotSettlementAmount: (
          //   <div className='text-right'>
          //     {couponNotSettlementAmount.toLocaleString()}
          //   </div>
          // ),
          // // otherSettlementAmount: (
          // //   <div className='text-right'>
          // //     {otherSettlementAmount.toLocaleString()}
          // //   </div>
          // // ),
          // preShippingTimeStamp:
          //   order?.preShippingTimeStamp &&
          //   moment(order.preShippingTimeStamp).format('YYYY-MM-DD HH:mm'),
          // arrivedTimeStamp:
          //   order.arrivedInfo?.recordedAt &&
          //   moment(order.arrivedInfo.recordedAt).format('YYYY-MM-DD HH:mm'),
          // canceledTimeStamp:
          //   order.refundedInfo?.recordedAt || order.canceledInfo?.recordedAt
          //     ? moment(
          //         order.refundedInfo?.recordedAt ||
          //           order.canceledInfo?.recordedAt,
          //       ).format('YYYY-MM-DD HH:mm')
          //     : '-',
          // orderConfirmedTimeStamp: moment(
          //   order.refundedInfo?.recordedAt ||
          //     order.canceledInfo?.recordedAt ||
          //     order.arrivedInfo?.recordedAt,
          // )
          //   .add(7, 'days')
          //   .format('YYYY-MM-DD HH:mm'),
        }
      })
  }, [taxInvoices?.length, pageIndex])

  return (
    <>
      <Page title='세금계산서 조회' Icon={InvoiceIcon}>
        <div className='p-10 mb-5 bg-gray-50 shadow-md rounded-xl min-w-[600px]'>
          <ul className='pl-5 space-y-2 list-disc'>
            <li>
              세금계산서 발행기준은 해당월의 구매확정일이며, 해당 월의 익월
              10일전에 발행됩니다.
            </li>
            <li>
              수수료대상 거래금액은 상품구매금액 + 배송비 + 올웨이즈 부담 할인금
              + 추가지원금액 - 취소금액 - 기 정산금액 - 기 정산 수수료로
              구성됩니다.
            </li>
            <li>
              <span className='text-red-500'>매출에누리</span>(올웨이즈 부담
              할인금)는 세금계산서 발급 시 올웨이즈 수수료수입 및 서비스수입에서
              차감되는 항목으로, 주문건 별 올웨이즈 부담 할인금으로, 수수료와
              특별수수료 합계를 한도로 합니다.
            </li>
            <li>수수료는 부가세 포함 금액입니다.</li>
            <li>
              세금계산서 상 광고수입은 엑셀파일 하단 정산제외금액에서
              확인가능합니다.
            </li>
          </ul>
          <div style={{ marginTop: 8 }}>
            <a
              href='https://placid-hoverfly-3d1.notion.site/ee12ed6ef7a04261b5e9d3a66ef993df'
              target='_blank'
              rel='noreferrer'
            >
              <Button>부가가치세 신고 가이드</Button>
            </a>
          </div>
        </div>

        {/* <Title text='조회기간 설정' />

        <div className='flex items-center justify-start gap-4 my-2'>
          <select className='form-select appearance-none w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}년
              </option>
            ))}
          </select>
          <select className='form-select appearance-none w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'>
            {months.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
          ~
          <select className='form-select appearance-none w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}년
              </option>
            ))}
          </select>
          <select className='form-select appearance-none w-36 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'>
            {months.map((month, index) => (
              <option key={month} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
          <Button className='px-6 py-1 transition duration-150 ease-in-out'>
            조회
          </Button>
        </div>
        <Divider /> */}

        {/* 엑셀 다운로드하는 버튼 */}
        {/* <Button
          size='sm'
          className='flex items-center whitespace-nowrap w-36'
          appearance='gray'
          type='button'
          onClick={async () => {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('정산대상 주문내역')
          }}
        >
          <SiGooglesheets className='mr-2' />
          세금계산서 다운로드
        </Button> */}
        {/* <Tabs tabContArr={tabContArr} /> */}
        <Table columns={taxInvoiceColumns} data={taxInvoiceData} />
        <Pagination
          page={pageIndex}
          setPage={setPageIndex}
          total={SellerStore.ordersForSettlements.length}
          limit={PAGE_ORDER_NUM}
        />
      </Page>
    </>
  )
})

export default TaxInvoiceScreen
