import React, { useState, useEffect, useRef } from 'react'
import { Card, Button, Row, Carousel, Spin } from 'antd'
import backendApis from '../../../utils/backendApis'
import BiddingOptionItemCard from './biddingOptionItemCard'
import biddingUtils from './biddingUtils'
import { FcApproval } from 'react-icons/fc'
import '../../../css/antd/carousel.css'

const apiGroups = {
  unitOption: {
    getTotalCount: backendApis.getUnitOptionTotalCount,
    getData: backendApis.getUnitOption,
    mapItem: backendApis.mappingItemToUnitOption,
  },
  generalOption: {
    getTotalCount: backendApis.getGeneralOptionTotalCount,
    getData: backendApis.getGeneralOption,
    mapItem: backendApis.mappingItemToGeneralOption,
  },
}

const BiddingOptionNudge = () => {
  const [biddingOptionList, setBiddingOptionList] = useState([])
  const [biddingType, setBiddingType] = useState('unitOption')
  const [loading, setLoading] = useState(false)
  const carouselRef = useRef(null) // Carousel을 제어하기 위한 ref

  useEffect(() => {
    const fetchData = async (type) => {
      if (loading) return
      setLoading(true)
      const result = await apiGroups[type].getData(
        {
          tab: type,
          keyword: null,
          filter: 'recommended',
          selectedImage: { $ne: null },
          deletedAt: null,
        },
        1,
        20,
      )
      if (result?.status === 200) {
        setBiddingOptionList(() => result?.data)
      }
      setLoading(false)
    }
    fetchData(biddingType)
  }, [biddingType])

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.goTo(0) // type이 변경될 때 슬라이드를 0번으로 초기화
    }
  }, [biddingOptionList]) // biddingOptionList의 변경도 감지하여 초기화

  return (
    <Card className='flex-1 flex h-full p-3 shadow-md rounded-xl'>
      <div className='flex flex-1 w-full flex-col h-full'>
        <Row className='font-bold text-lg'>신선마켓 입찰 현황</Row>
        <Row>최저가 상품이 가장 잘 팔리는 특가전</Row>

        <div className='flex flex-1 w-full items-end justify-end flex-col h-full mt-5'>
          <div className='flex flex-1 w-full items-end justify-end flex-col'>
            <div className='mb-2 flex flex-1 w-full items-center justify-between'>
              <div className='flex flex-1 items-center text-slate-500'>
                <FcApproval className='mr-1' /> 지금 1위에 도전해보세요!
              </div>
              <Row>
                <Button
                  className={`mr-2 ${
                    biddingType === 'unitOption'
                      ? 'border-blue-500 text-blue-500'
                      : ''
                  }`}
                  onClick={() => setBiddingType('unitOption')}
                >
                  원물 카탈로그
                </Button>
                <Button
                  className={`${
                    biddingType === 'generalOption'
                      ? 'border-blue-500 text-blue-500'
                      : ''
                  }`}
                  onClick={() => setBiddingType('generalOption')}
                >
                  브랜드 카탈로그
                </Button>
              </Row>
            </div>
            <Row>
              {loading && (
                <Row
                  justify='center'
                  align='center'
                  className='flex flex-1 w-full h-full justify-center items-center bg-white text-slate-500'
                  style={{ width: '550px', height: '200px' }}
                >
                  <Spin tip='로딩 중...' className='mr-2' />
                  <div>로딩 중</div>
                </Row>
              )}
              {biddingOptionList?.length > 0 && !loading && (
                <Carousel
                  ref={carouselRef}
                  className='custom-carousel-dots'
                  dots
                  autoplay
                  effect='scrollx'
                  style={{ width: '550px', height: '200px' }}
                  draggable
                  infinite
                >
                  {biddingOptionList?.map((biddingInfo, idx) => {
                    const {
                      _id,
                      topOptions = [],
                      externalPriceInfo = [],
                    } = biddingInfo || {}
                    const selectedLiveBiddingPrice =
                      biddingUtils.liveBiddingPriceCalc(
                        topOptions,
                        externalPriceInfo,
                      )
                    return (
                      <div
                        className='flex h-[200px] flex-1 w-full cursor-pointer'
                        key={_id?.toString() + idx?.toString()}
                        onClick={() =>
                          window.open(
                            'https://alwayzseller.ilevit.com/unit-option',
                            '_blank',
                          )
                        }
                        aria-hidden
                      >
                        <BiddingOptionItemCard
                          biddingOptionInfo={biddingInfo}
                          liveBiddingPrice={selectedLiveBiddingPrice}
                          type={biddingType}
                          simple
                        />
                      </div>
                    )
                  })}
                </Carousel>
              )}
            </Row>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default BiddingOptionNudge
