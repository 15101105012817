import React, { useCallback, useState } from 'react'
import { Button, Col, Tooltip, Row, Tabs, Dropdown, Menu, message } from 'antd'
import { QuestionCircleTwoTone, DownOutlined } from '@ant-design/icons'
import Search from 'antd/es/input/Search'
import ExplainModal from '../components/unitOption/bidding/explainModal'
import OptionBiddingSection from '../components/unitOption/bidding/optionBiddingSection'
import TabPane from 'antd/es/tabs/TabPane'
import DataPanelSection from '../components/unitOption/bidding/dataPanelSection'

const Explanation = ({ onExplainClick = () => {} }) => (
  <Col span={24}>
    <Row justify='space-between'>
      <Col span={12}>
        <div>
          올웨이즈 신선마켓은 100%{' '}
          <span className='font-bold text-blue-500'>핵심 카탈로그 상품</span>
          <Tooltip
            className='mx-1'
            title='올웨이즈 판매량을 중심으로 선정된 핵심 옵션입니다.'
          >
            <QuestionCircleTwoTone />
          </Tooltip>
          을 중심으로 운영될 예정입니다.
        </div>
        <div>
          핵심 카탈로그 상품에 연동된 상품 중{' '}
          <span className='font-bold text-blue-500'>실시간 최저가 상품</span>
          <Tooltip
            className='mx-1'
            title='최대 10분 간격으로 동일 카탈로그 입찰 상품 중 최저가 상품으로 자동 선정됩니다.'
          >
            <QuestionCircleTwoTone />
          </Tooltip>
          만 노출될 예정입니다.
        </div>
        <div className='text-gray-500 mt-4'>
          *신선마켓 추가 수수료는 없습니다.(기본 수수료 적용, 3.5%)
        </div>
      </Col>
      <Col span={6}>
        <div className='font-bold text-slate-500 mb-2'>신선마켓</div>

        <Button
          type='default'
          onClick={() => {
            onExplainClick()
          }}
          className='bg-white'
        >
          노출 혜택 상세보기
        </Button>
      </Col>
      <Col span={6}>
        <Row align='bottom'>
          <Col>
            <div className='font-bold text-slate-500 mb-2'>신선마켓 기간</div>
            <div className='text-slate-500'>2024.09.24 ~ 2024.10.24</div>
          </Col>
        </Row>
      </Col>
    </Row>
  </Col>
)

const filterNames = {
  recommended: '추천순',
  sales: '판매량순',
  intensityLow: '경쟁강도 낮은순',
  intensityHigh: '경쟁강도 높은순',
}

const OptionBiddingScreen = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [ignoreCookie, setIgnoreCookie] = useState(false)
  const [modalState, setModalState] = useState({
    explainModalVisible: false,
  })
  const [optionBiddingCondition, setOptionBiddingCondition] = useState(() => ({
    tab: 'unitOption',
    keyword: null,
    filter: 'recommended', // 추천순을 기본값으로 설정
  }))
  const [viewMode, setViewMode] = useState('all')
  const [selectedFilter, setSelectedFilter] = useState('추천순') // 기본값 설정
  const [activeButton, setActiveButton] = useState('all') // Track active button
  const { tab } = optionBiddingCondition

  const handleTabChange = useCallback((tab) => {
    setOptionBiddingCondition((prevState) => ({
      ...prevState,
      tab,
      viewMode: 'all',
      keyword: null,
    }))
    setViewMode('all')
    setActiveButton('all') // Reset active button when tab changes
    const defaultFilter = tab === 'unitOption' ? 'recommended' : 'sales'
    setOptionBiddingCondition((prevState) => ({
      ...prevState,
      filter: defaultFilter,
    }))

    // Update the selected filter name
    setSelectedFilter(filterNames[`${defaultFilter}`])
  }, [])

  const handleMenuClick = (e) => {
    if (isLoading) {
      message.warning('데이터를 불러오는 중입니다. 잠시만 기다려주세요.')
      return
    }
    const { key } = e

    // Mapping menu keys to filter names

    // Update bidding condition with the selected filter
    setOptionBiddingCondition((prevState) => ({
      ...prevState,
      filter: key,
    }))

    // Update the selected filter name
    setSelectedFilter(filterNames[key])
  }

  const filterMenu = (
    <Menu onClick={handleMenuClick}>
      {tab === 'unitOption' && <Menu.Item key='recommended'>추천순</Menu.Item>}
      <Menu.Item key='sales'>판매량순</Menu.Item>
      <Menu.Item key='intensityLow'>경쟁강도 낮은순</Menu.Item>
      <Menu.Item key='intensityHigh'>경쟁강도 높은순</Menu.Item>
    </Menu>
  )

  const StatusTabSection = () => {
    return (
      <Row justify='space-between' style={{ marginBottom: 16 }}>
        <Col>
          <Button
            type='default'
            onClick={() => {
              if (isLoading)
                return message.warning(
                  '데이터를 불러오는 중입니다. 잠시만 기다려주세요.',
                )
              if (activeButton !== 'all') {
                // Only trigger if not already active
                setOptionBiddingCondition((prevState) => ({
                  ...prevState,
                  viewMode: 'all',
                }))
                setViewMode('all')
                setActiveButton('all') // Set as active
              }
            }}
            className={
              viewMode === 'all'
                ? 'bg-white text-blue-500 border-blue-500'
                : 'bg-white'
            }
          >
            전체
          </Button>
          <Button
            type='default'
            onClick={() => {
              if (isLoading)
                return message.warning(
                  '데이터를 불러오는 중입니다. 잠시만 기다려주세요.',
                )
              if (activeButton !== 'applied') {
                // Only trigger if not already active
                setOptionBiddingCondition((prevState) => ({
                  ...prevState,
                  viewMode: 'applied',
                }))
                setViewMode('applied')
                setActiveButton('applied') // Set as active
              }
            }}
            className={
              viewMode === 'applied'
                ? 'bg-white text-blue-500 border-blue-500'
                : 'bg-white'
            }
            style={{ marginLeft: 8 }}
          >
            신청 완료
          </Button>
        </Col>
        <Col>
          <Dropdown overlay={filterMenu} disabled={viewMode === 'applied'}>
            <Button style={{ backgroundColor: 'white' }}>
              {selectedFilter} <DownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    )
  }

  return (
    <Row className='w-full mt-10 mb-20'>
      <div className='flex flex-1 w-full flex-col'>
        <Row className='font-bold text-xl mb-5'>신선마켓 카탈로그 입찰</Row>
        <div className='flex flex-1 flex-col w-full p-6 rounded-md bg-slate-200 mb-5 max-w-7xl'>
          <Explanation
            onExplainClick={() => {
              setIgnoreCookie(true)
              setModalState((prevState) => ({
                ...prevState,
                explainModalVisible: true,
              }))
            }}
          />
        </div>
      </div>
      <Col span={24}>
        <div className='flex flex-1 flex-col max-w-7xl'>
          <div className='max-w-2xl'>
            <Search
              key={`${tab}_witt`}
              className='mb-5'
              styles={{
                input: {
                  height: '32px',
                  borderColor: '#cbd5e1',
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                },
              }}
              enterButton={
                <Button type='primary' style={{ backgroundColor: '#3b82f6' }}>
                  검색하기
                </Button>
              }
              placeholder='ex) 감자'
              onSearch={(k) => {
                let keyword = k
                if (keyword === '') keyword = null
                setOptionBiddingCondition((prev) => ({ ...prev, keyword }))
              }}
            />
          </div>
          <Tabs activeKey={tab} onChange={handleTabChange}>
            <TabPane tab='원물 카탈로그' key='unitOption'>
              {tab === 'unitOption' && (
                <>
                  <StatusTabSection />
                  <OptionBiddingSection
                    condition={optionBiddingCondition}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </>
              )}
            </TabPane>
            <TabPane tab='브랜드 카탈로그' key='generalOption'>
              {tab === 'generalOption' && (
                <>
                  <StatusTabSection />
                  <OptionBiddingSection
                    condition={optionBiddingCondition}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </>
              )}
            </TabPane>
            <TabPane
              tab={
                <div style={{ position: 'relative', display: 'inline-block' }}>
                  1위 탈환
                  <span className='absolute -top-1 -right-4 bg-red-500 text-white px-1 py-0.3 rounded text-[8px]'>
                    N
                  </span>
                </div>
              }
              key='dataPanel'
            >
              {tab === 'dataPanel' && <DataPanelSection />}
            </TabPane>
          </Tabs>
        </div>
      </Col>
      <ExplainModal
        isVisible={modalState.explainModalVisible}
        onCancel={() =>
          setModalState((prevState) => ({
            ...prevState,
            explainModalVisible: false,
          }))
        }
        ignoreCookie={ignoreCookie}
      />
    </Row>
  )
}

export default OptionBiddingScreen
